import { createSelector as ngrxCreateSelector } from '@ngrx/store';
import { createSelector } from 'reselect';

import { getUserType } from '../auth/auth.service';
import { getPermissionState, hasPermission, PermissionCheck } from '../auth/permission.helper';
import { featureStore } from '../feature/feature.state';
import { getAccount } from './account.service';

export const canViewSubscription = createSelector(
  getUserType,
  getAccount,
  getPermissionState,
  (userType, account, permissionState) => {
    if (!account) {
      return false;
    } else {
      if (userType === 'serviceDeskEmployee') {
        return true;
      }
      if (userType === 'resellerEmployee') {
        return true;
      }
      const check: PermissionCheck = {
        userId: 'me',
        departments: 'any',
        permissions: ['Manage account'],
      };

      return hasPermission(check, permissionState) && !account.send_invoice_to_reseller;
    }
  },
);

export const selectShowCocForAbsences = ngrxCreateSelector(
  getAccount,
  featureStore.selectFeatureEnabled('tmp_coc_absences'),
  (account, featureCocAbsences) => featureCocAbsences && account?.coc_in_schedule,
);
