import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import * as FeatureActions from './feature.actions';

export interface FeatureToggle {
  key: string;
  enabled: boolean;
}

export interface FeatureState extends EntityState<FeatureToggle> {}

const adapter: EntityAdapter<FeatureToggle> = createEntityAdapter<FeatureToggle>({
  selectId: (e) => e.key,
  sortComparer: (a, b) => a.key.localeCompare(b.key),
});

export const initialState: FeatureState = adapter.getInitialState({});

export const featureStore = createFeature({
  name: 'features',
  reducer: createReducer(
    initialState,
    on(FeatureActions.updateFeatureToggles, (state, { toggles }) => adapter.setAll(toggles, state)),
  ),
  extraSelectors: ({ selectEntities }) => ({
    selectFeatureEnabled: (key: string) =>
      createSelector(selectEntities, (features) => features[key]?.enabled || false),
  }),
});
