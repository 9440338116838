/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { AppState } from '@app/reducers';
import { selectShowCocForAbsences } from '@app/reducers/account/account.selector';
import { PermissionState } from '@app/reducers/auth/auth.model';
import { DaylogsLoadRequest } from '@app/reducers/orm/daylog/daylog.api';
import { PermissionOption } from '@app/reducers/orm/permission/permission.model';
import { Store } from '@ngrx/store';
import { getAccountCocInSchedule, getAccountSubscription } from '@reducers/account/account.service';
import {
  canPrintTimesheetStatement,
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getPermissionState,
  hasPermission,
  PermissionCheck,
  permissionDepartments,
} from '@reducers/auth/permission.helper';
import { absenceInPeriodOrTimeRangeForTimesheet } from '@reducers/orm/absence/absence.helper';
import { AbsenceModel } from '@reducers/orm/absence/absence.model';
import {
  absenceForPeriodWithSum,
  AbsenceService,
  getAbsenceEnhanced,
  sumPeriodAbsence,
} from '@reducers/orm/absence/absence.service';
import { contractInfoPerEmployeePerDay, ContractService, getContracts } from '@reducers/orm/contract/contract.service';
import { DaylogModel } from '@reducers/orm/daylog/daylog.model';
import {
  DaylogService,
  departmentDayIsClosed,
  getDaylogs,
  getSelectedDayLogs,
} from '@reducers/orm/daylog/daylog.service';
import { getDepartmentEntities, mapAndSortDepartments } from '@reducers/orm/department/department.service';
import { EmployeeModel } from '@reducers/orm/employee/employee.model';
import {
  employeeActiveInPeriod,
  employeeInDepartment,
  employeeNameFilter,
  getEmployeeEntities,
  mapAndSortEmployees,
} from '@reducers/orm/employee/employee.service';
import { getDepartmentOptionsPerLocation, getLocationEntities } from '@reducers/orm/location/location.service';
import { getRateCards } from '@reducers/orm/rate-card/rate-card.service';
import { ScheduleModel, SchedulesLoadRequest } from '@reducers/orm/schedule/schedule.model';
import {
  getEnhancedSchedules,
  hasSchedulePermission,
  ScheduleService,
  sumSchedules,
} from '@reducers/orm/schedule/schedule.service';
import { getShifts } from '@reducers/orm/shift/shift.service';
import { TeamModel } from '@reducers/orm/team/team.model';
import { getTeamEntities, getTeams, groupTeamsByDepartment } from '@reducers/orm/team/team.service';
import { TimesheetConflictRequest } from '@reducers/orm/timesheet-conflict/timesheet-conflict.model';
import { TimesheetConflictService } from '@reducers/orm/timesheet-conflict/timesheet-conflict.service';
import {
  TimesheetModel,
  TimesheetModelEnchanced,
  TimesheetsLoadRequest,
} from '@reducers/orm/timesheet/timesheet.model';
import {
  getTimesheets,
  hasTimesheetPermission,
  sumTimesheets,
  TimesheetService,
} from '@reducers/orm/timesheet/timesheet.service';
import {
  TimesheetShowFilters,
  TimesheetStatusFilters,
  TimesheetTypeFilters,
} from '@reducers/page-filters/page-filters.model';
import { getTimesheetFilters } from '@reducers/page-filters/page-filters.service';
import {
  departmentFilter,
  getSelectedDepartmentIds,
} from '@reducers/selected-departments/selected-departments.service';
import { combinedFilter, filterDeleted } from '@reducers/shared/entity.helper';
import {
  appendSecondsToTimeString,
  dayList,
  determineEndTimeForComparison,
  format,
  maxToday,
  parseDate,
  periodFilter,
} from '@shared/date.helper';
import { lazySelect } from '@shared/lazy-select.observable';
import { hasAtleastSubscriptionPlan } from '@shared/subscription-plan/subscription-plan.directive';
import { defaultTotal, totalAccumulator } from '@shared/total.helper';
import { addDays, endOfDay, subDays } from 'date-fns';
import difference from 'lodash-es/difference';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import some from 'lodash-es/some';
import union from 'lodash-es/union';
import { createSelector } from 'reselect';
import { combineLatest, forkJoin, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { PlanType } from '../../+reports/shared/subscriptions/subscription.model';
import { isOverlappingAcrossDay } from '../../+schedule/shared/schedule-conflict.helper';
import { TimesheetDayEmployee, TimesheetEmployeeDayData } from './day/interfaces';
import { TimesheetRow, TimesheetRowAbsentee, TimesheetRowTimesheet, TimesheetScheduleDeviations } from './interfaces';

@Injectable()
export class TimesheetHelperService {
  public constructor(
    private store: Store<AppState>,
    private timesheetService: TimesheetService,
    private scheduleService: ScheduleService,
    private absenceService: AbsenceService,
    private daylogService: DaylogService,
    private contractService: ContractService,
    private timesheetConflictService: TimesheetConflictService,
  ) {}

  public loadDataObs(minDate: Date, maxDate: Date, userId?: string, selectedDepartmentIds?: string[]) {
    const period = {
      minDate: format(minDate, 'yyyy-MM-dd'),
      maxDate: format(maxDate, 'yyyy-MM-dd'),
      userId,
    };

    return forkJoin([
      this.loadTimesheets(period, selectedDepartmentIds),
      // Consider Show all Shifts filter always enabled for Timesheet
      this.loadSchedules(period, selectedDepartmentIds, true),
      this.loadAbsence(period),
      this.loadDaylogs(period, selectedDepartmentIds),
      this.loadContracts(period),
      period.userId
        ? of(null)
        : this.loadConflicts({
            from: period.minDate,
            to: period.maxDate,
            departmentId: selectedDepartmentIds,
          }),
    ]);
  }

  public loadOpenCloseData(minDate: Date, maxDate: Date) {
    const period = {
      minDate: format(minDate, 'yyyy-MM-dd'),
      maxDate: format(maxDate, 'yyyy-MM-dd'),
    };

    return forkJoin([this.loadTimesheets(period), this.loadDaylogs(period)]);
  }

  public userDataObs(minDate: Date, maxDate: Date, userId: string) {
    const period = {
      minDate: format(minDate, 'yyyy-MM-dd'),
      maxDate: format(maxDate, 'yyyy-MM-dd'),
      userId,
    };

    return forkJoin([this.loadTimesheets(period), this.loadSchedules(period), this.loadAbsence(period)]);
  }

  public periodObs(minDate: Date, maxDate: Date) {
    const period = {
      minDate: format(minDate, 'yyyy-MM-dd'),
      maxDate: format(maxDate, 'yyyy-MM-dd'),
    };
    return this.loadDaylogs(period);
  }

  private loadTimesheets(period, departmentIds?: string[]) {
    const requestData: TimesheetsLoadRequest = {
      ...period,
      rates: true,
      departmentId: departmentIds,
    };

    return this.timesheetService.load(requestData).pipe(first());
  }

  private loadSchedules(period, departmentIds?: string[], loanedShiftsFilter?: boolean) {
    const requestData: SchedulesLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
    };

    if (!loanedShiftsFilter) {
      requestData.departmentId = departmentIds;
    }

    return this.scheduleService.load(requestData).pipe(first());
  }

  private loadContracts(period) {
    return this.contractService.load(period).pipe(first());
  }

  public loadConflicts(request: TimesheetConflictRequest) {
    const check: PermissionCheck = {
      permissions: ['View all timesheets', 'View own timesheet'],
      userId: 'me',
      departments: 'any',
    };

    return combineLatest([this.store.select(getAccountSubscription), this.store.select(getPermissionState)]).pipe(
      map(
        ([accountSubscription, permissionState]) =>
          hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription) && hasPermission(check, permissionState),
      ),
      switchMap((hasPerm: boolean) =>
        hasPerm ? this.timesheetConflictService.loadConflicts(request).pipe(first()) : of(undefined),
      ),
      first(),
    );
  }

  private loadAbsence(period) {
    const check: PermissionCheck = {
      permissions: ['View absentee', 'View own absentee'],
      userId: 'me',
      departments: 'any',
    };

    const searchParams = {
      ...period,
      include: 'AbsenteeDay,AbsenteeDetail',
    };

    return this.store.pipe(
      lazySelect(getPermissionState),
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => (hasPerm ? this.absenceService.load(searchParams).pipe(first()) : of(undefined))),
      first(),
    );
  }

  private loadDaylogs(period, departmentIds?: string[]) {
    const requestData: DaylogsLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.daylogService.load(requestData).pipe(first());
  }
}

interface RowFilterSettings {
  show: TimesheetShowFilters;
  status: TimesheetStatusFilters;
  type: TimesheetTypeFilters;
}

export const timesheetRowFilter = (filters: RowFilterSettings) => (employeeRow) => {
  // Show Filters
  if (employeeRow.type === 'absentee') {
    return filters.show.absence;
  }
  if (employeeRow.type === 'schedule') {
    return filters.show.schedule;
  }

  // It's a timesheet row :)

  // Status filters
  if (!filters.status.pending && employeeRow.timesheet.status === 'Pending') {
    return false;
  }
  if (!filters.status.approved && employeeRow.timesheet.status === 'Approved') {
    return false;
  }
  if (!filters.status.declined && employeeRow.timesheet.status === 'Declined') {
    return false;
  }

  const hasConflict =
    employeeRow.absenceWarning || employeeRow.deviationsFromSchedule.hasDeviations || employeeRow.doubleRegistration;
  if (filters.type.onlyConflict && !hasConflict) {
    return false;
  }

  // Type filters
  if (!filters.type.activeClock && employeeRow.timesheet.active_clock) {
    return false;
  }
  if (!filters.type.clocked && employeeRow.timesheet.clock && !employeeRow.timesheet.active_clock) {
    return false;
  }

  if (!filters.type.manual && !employeeRow.timesheet.clock) {
    return false;
  }

  return true;
};

export const getTimesheetMinDate = createSelector(getTimesheetFilters, (filters) =>
  filters['period'] && filters['period'].minDate ? filters['period'].minDate : format(new Date(), 'yyyy-MM-dd'),
);

export const getTimesheetMaxDate = createSelector(getTimesheetFilters, (filters) =>
  filters['period'] && filters['period'].maxDate ? filters['period'].maxDate : format(new Date(), 'yyyy-MM-dd'),
);
export const getTimesheetColumns = createSelector(
  getTimesheetFilters,
  getAccountSubscription,
  (filters, accountSubscription) =>
    mapValues(filters.columns, (value, column) => {
      if (column === 'km' || column === 'meals') {
        if (!hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, accountSubscription)) {
          return false;
        }
        return value;
      }

      if (column === 'surcharge') {
        if (!hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription)) {
          return false;
        }
        return value;
      }
      return value;
    }),
);

export const getTimesheetEmployeeNameFilter = createSelector(getTimesheetFilters, (filters) => {
  const filterValue = filters.name;

  return employeeNameFilter(filterValue);
});

export const getTimesheetRowFilterSettings = createSelector(getTimesheetFilters, (filters) => ({
  show: filters.show,
  status: filters.status,
  type: filters.type,
  customFields: filters.customFields,
}));

export const getTimesheetRowFilter = createSelector(getTimesheetRowFilterSettings, (filters) =>
  timesheetRowFilter(filters),
);

export const getTimesheetsForTimesheetPageWithoutDepartmentFilter = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getTimesheets,
  (minDate, maxDate, permissionState, timesheets): TimesheetModel[] =>
    combinedFilter(
      timesheets,
      periodFilter(minDate, maxToday(maxDate)),
      hasTimesheetPermission(['View all timesheets', 'View own timesheet'], permissionState),
    ),
);

export const getTimesheetsForTimesheetPageWithoutDepartmentFilterWithCoC = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getTimesheets,
  getAccountCocInSchedule,
  (minDate, maxDate, permissionState, timesheets, cocInScheduleActivated): TimesheetModel[] =>
    combinedFilter(
      timesheets,
      periodFilter(minDate, maxToday(maxDate)),
      hasTimesheetPermission(['View all timesheets', 'View own timesheet'], permissionState),
    ).map((timesheet) => {
      if (cocInScheduleActivated) {
        return { ...timesheet, salary: timesheet.coc };
      }
      return timesheet;
    }),
);

export const getTimesheetsForTimesheetPage = createSelector(
  getTimesheetsForTimesheetPageWithoutDepartmentFilter,
  getSelectedDepartmentIds,
  (timesheets, selectedDepartments) => timesheets.filter(departmentFilter(selectedDepartments)),
);

export const getTimesheetsForTimesheetPageWithCoc = createSelector(
  getTimesheetsForTimesheetPageWithoutDepartmentFilterWithCoC,
  getSelectedDepartmentIds,
  getAccountCocInSchedule,
  (timesheets, selectedDepartments, cocInScheduleActivated) =>
    timesheets.filter(departmentFilter(selectedDepartments)).map((timesheet) => {
      if (cocInScheduleActivated) {
        return { ...timesheet, salary: timesheet.coc };
      }
      return timesheet;
    }),
);

export const getSchedulesForTimesheet = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getEnhancedSchedules,
  (minDate, maxDate, permissionState, schedules) => {
    const combinedFilters = [
      periodFilter(minDate, maxToday(maxDate)),
      hasSchedulePermission(['View all rosters', 'View own roster'], permissionState),
      (schedule) => !schedule.Shift.is_task,
    ];

    return combinedFilter(schedules, ...combinedFilters);
  },
);

export const getSchedulesForTimesheetDayPage = createSelector(
  getSchedulesForTimesheet,
  getSelectedDepartmentIds,
  (schedules, selectedDepartments) => schedules.filter(departmentFilter(selectedDepartments)),
);

export const getAbsenceForTimesheetPage = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getEmployeeTeamDepartments,
  getAbsenceEnhanced,
  selectShowCocForAbsences,
  //callback
  absenceForPeriodWithSum,
);

export const getDaylogsForTimesheet = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getSelectedDayLogs,
  (minDate, maxDate, dayLogs) => dayLogs.filter(periodFilter(minDate, maxToday(maxDate))),
);

export const getEmployeesForTimesheetDayPage = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getEmployeeEntities,
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getTimesheetsForTimesheetPage,
  getSelectedDepartmentIds,
  getTeamEntities,
  getSchedulesForTimesheetDayPage,
  getAbsenceForTimesheetPage,
  getPermissionState,
  mapAndSortEmployees,
  getTimesheetEmployeeNameFilter,
  getTimesheetRowFilterSettings,
  getContracts,
  (
    minDate,
    maxDate,
    employeeEntities,
    employeeTeamDepartments,
    timesheets,
    departmentIds,
    teamEntities,
    schedules,
    absences,
    permissionState,
    sortFn,
    nameFilter,
    rowFilterSettings,
    contracts,
  ) => {
    const activeEmployees = pickBy(employeeEntities, employeeActiveInPeriod(minDate, maxToday(maxDate)));

    const timesheetEmployeeIds = timesheets.map((timesheet) => timesheet.user_id);
    const scheduledEmployeeIds = schedules.map((schedule) => schedule.user_id);
    const absencesEmployeeIds = absences.map((absence) => absence.user_id);
    const combinedEmployeeIds = union(timesheetEmployeeIds, scheduledEmployeeIds, absencesEmployeeIds);
    const inDepFilter = employeeInDepartment(departmentIds, teamEntities, combinedEmployeeIds, false);
    const employeesWithinSelectedDepartments = Object.keys(pickBy(employeeEntities, inDepFilter));
    let employeeIdSource = combinedEmployeeIds;
    if (rowFilterSettings.show.employee) {
      employeeIdSource = employeesWithinSelectedDepartments;
    }

    //only show employees for which The user can see the timesheets
    const permittedEmployeeIds = employeeIdSource.filter((employeeId: string) => {
      const employeeDepartments = employeeTeamDepartments[employeeId];

      return hasPermission(
        {
          permissions: ['View all timesheets', 'View own timesheet'],
          userId: employeeId,
          departments: employeeDepartments,
        },
        permissionState,
      );
    });

    const contractInfo = contractInfoPerEmployeePerDay(minDate, maxDate, contracts);

    return sortFn(permittedEmployeeIds, activeEmployees)
      .filter(nameFilter)
      .map((employee) => {
        const contractInfoPerDay = contractInfo[employee.id];
        return { ...employee, contractInfoPerDay };
      });
  },
);

export const getEmployeesForTimesheetDayPageNew = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getEmployeeEntities,
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getTimesheetsForTimesheetPage,
  getSelectedDepartmentIds,
  getTeamEntities,
  getSchedulesForTimesheetDayPage,
  getAbsenceForTimesheetPage,
  getPermissionState,
  mapAndSortEmployees,
  getTimesheetEmployeeNameFilter,
  getTimesheetRowFilterSettings,
  getContracts,
  (
    minDate,
    maxDate,
    employeeEntities,
    employeeTeamDepartments,
    timesheets,
    departmentIds,
    teamEntities,
    schedules,
    absences,
    permissionState,
    sortFn,
    nameFilter,
    rowFilterSettings,
    contracts,
  ) => {
    const timesheetEmployeeIds = timesheets.map((timesheet) => timesheet.user_id);
    const scheduledEmployeeIds = schedules.map((schedule) => schedule.user_id);
    const absencesEmployeeIds = absences.map((absence) => absence.user_id);
    const combinedEmployeeIds = union(timesheetEmployeeIds, scheduledEmployeeIds, absencesEmployeeIds);

    const activeEmployees = pickBy(employeeEntities, employeeActiveInPeriod(minDate, maxToday(maxDate)));

    const inDepFilter = employeeInDepartment(departmentIds, teamEntities, combinedEmployeeIds, false);
    const employeesWithinSelectedDepartments = Object.keys(pickBy(employeeEntities, inDepFilter));
    let employeeIdSource = combinedEmployeeIds;
    if (rowFilterSettings.show.employee) {
      employeeIdSource = employeesWithinSelectedDepartments;
    }

    // only show employees for which The user can see or approve the timesheets
    const permittedEmployeeIds = employeeIdSource.filter((employeeId: string) => {
      const employeeDepartments = employeeTeamDepartments[employeeId];

      const canView = hasPermission(
        {
          permissions: ['View all timesheets', 'View own timesheet'],
          userId: employeeId,
          departments: employeeDepartments,
        },
        permissionState,
      );
      const canApprove = hasPermission(
        {
          permissions: ['Approve timesheets'],
          userId: null,
          departments: 'any',
        },
        permissionState,
      );
      return canView || canApprove;
    });

    const contractInfo = contractInfoPerEmployeePerDay(minDate, maxDate, contracts);

    return sortFn(permittedEmployeeIds, activeEmployees)
      .filter(nameFilter)
      .map((employee) => {
        const contractInfoPerDay = contractInfo[employee.id];
        return { ...employee, contractInfoPerDay };
      });
  },
);

export const getEmployeesForTimesheetDayPageNewCoc = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getEmployeeEntities,
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getTimesheetsForTimesheetPageWithCoc,
  getSelectedDepartmentIds,
  getTeamEntities,
  getSchedulesForTimesheetDayPage,
  getAbsenceForTimesheetPage,
  getPermissionState,
  mapAndSortEmployees,
  getTimesheetEmployeeNameFilter,
  getTimesheetRowFilterSettings,
  getContracts,
  (
    minDate,
    maxDate,
    employeeEntities,
    employeeTeamDepartments,
    timesheets,
    departmentIds,
    teamEntities,
    schedules,
    absences,
    permissionState,
    sortFn,
    nameFilter,
    rowFilterSettings,
    contracts,
  ) => {
    const timesheetEmployeeIds = timesheets.map((timesheet) => timesheet.user_id);
    const scheduledEmployeeIds = schedules.map((schedule) => schedule.user_id);
    const absencesEmployeeIds = absences.map((absence) => absence.user_id);
    const combinedEmployeeIds = union(timesheetEmployeeIds, scheduledEmployeeIds, absencesEmployeeIds);

    const activeEmployees = pickBy(employeeEntities, employeeActiveInPeriod(minDate, maxToday(maxDate)));

    const inDepFilter = employeeInDepartment(departmentIds, teamEntities, combinedEmployeeIds, false);
    const employeesWithinSelectedDepartments = Object.keys(pickBy(employeeEntities, inDepFilter));
    let employeeIdSource = combinedEmployeeIds;
    if (rowFilterSettings.show.employee) {
      employeeIdSource = employeesWithinSelectedDepartments;
    }

    // only show employees for which The user can see or approve the timesheets
    const permittedEmployeeIds = employeeIdSource.filter((employeeId: string) => {
      const employeeDepartments = employeeTeamDepartments[employeeId];

      const canView = hasPermission(
        {
          permissions: ['View all timesheets', 'View own timesheet'],
          userId: employeeId,
          departments: employeeDepartments,
        },
        permissionState,
      );
      const canApprove = hasPermission(
        {
          permissions: ['Approve timesheets'],
          userId: null,
          departments: 'any',
        },
        permissionState,
      );
      return canView || canApprove;
    });

    const contractInfo = contractInfoPerEmployeePerDay(minDate, maxDate, contracts);

    return sortFn(permittedEmployeeIds, activeEmployees)
      .filter(nameFilter)
      .map((employee) => {
        const contractInfoPerDay = contractInfo[employee.id];
        return { ...employee, contractInfoPerDay };
      });
  },
);

//getTimesheetEmployees ( active for period )
export const enhanceTimesheetsWithLogAndPermissions = (
  timesheets: TimesheetModel[],
  daylogs: DaylogModel[],
  permissionState: PermissionState,
): TimesheetModelEnchanced[] => {
  const permissionChecks = {
    canApprove: hasTimesheetPermission('Approve timesheets', permissionState),
    canViewTimesheetModal: hasTimesheetPermission(['View all timesheets'], permissionState),
    canEdit: hasTimesheetPermission(['Edit timesheets', 'Edit own timesheet'], permissionState),
    canEditClocked: hasTimesheetPermission(['Edit clocked time', 'Edit own clocked time'], permissionState),
    canClockOut: hasTimesheetPermission(['Clock time', 'Edit clocked time', 'Edit own clocked time'], permissionState),
    canViewClockTime: hasTimesheetPermission(['View own clock time'], permissionState),
  };

  return timesheets.map((timesheet) => {
    const closed = departmentDayIsClosed(timesheet.date, timesheet.department_id, daylogs);
    const permissions = {
      canEdit: false,
      canViewTimesheetModal: false,
      canApprove: false,
      canClockOut: false,
    };

    if (!closed) {
      permissions.canApprove = permissionChecks.canApprove(timesheet) && !timesheet.active_clock;
      permissions.canViewTimesheetModal = permissionChecks.canViewTimesheetModal(timesheet);
      permissions.canEdit = timesheet.clock
        ? permissionChecks.canEditClocked(timesheet)
        : permissionChecks.canEdit(timesheet);
      permissions.canClockOut = timesheet.active_clock && permissionChecks.canClockOut(timesheet);

      if (permissions.canEdit && !permissions.canApprove && timesheet.status === 'Approved') {
        permissions.canEdit = false;
      }
    }

    // for now we have to check if the user is the owner of the timesheet
    // this is due to the fact that we do not have a generic permission for `View clock time`
    // without this permission we have to fall back to true when the user is not the owner of the timesheet
    const canViewClockTime =
      permissionState.userId === timesheet.user_id ? permissionChecks.canViewClockTime(timesheet) : true;

    return {
      ...timesheet,
      closed,
      ...permissions,
      canViewClockTime,
    };
  });
};

const checkTimesheetAbsenceWarning = (timesheet: TimesheetModel, absence: AbsenceModel[]) => {
  if (absence.length === 0) {
    return false;
  }

  return some(absence, (absentee) => {
    if (absentee.status !== 'Approved') {
      return false;
    }

    return absenceInPeriodOrTimeRangeForTimesheet(timesheet)(absentee);
  });
};

const checkOverlappingTimesheetWarning = (check: TimesheetModel, timesheets: TimesheetModel[]) => {
  if (check.status === 'Declined') {
    return false;
  }

  let checkEndTime = !check.active_clock ? check.endtime : format(new Date(), 'H:m:s');
  checkEndTime = determineEndTimeForComparison(check.starttime, checkEndTime);

  const checkDate = parseDate(check.date);
  const previousDay = format(subDays(checkDate, 1), 'yyyy-MM-dd');
  const nextDay = format(addDays(checkDate, 1), 'yyyy-MM-dd');

  return some(timesheets, (timesheet) => {
    if (timesheet.status === 'Declined') {
      return false;
    }

    if (timesheet.id === check.id) {
      return false;
    }

    if (timesheet.user_id !== check.user_id) {
      return false;
    }

    if (timesheet.date !== check.date) {
      return isOverlappingAcrossDay(previousDay, nextDay, check, timesheet);
    }

    let timesheetEndTime = !timesheet.active_clock ? timesheet.endtime : format(new Date(), 'H:m:s');
    timesheetEndTime = determineEndTimeForComparison(timesheet.starttime, timesheetEndTime);

    //check for overlap between timesheet and absence
    return timesheet.starttime < checkEndTime && timesheetEndTime > check.starttime;
  });
};

export function getDeviationsFromSchedule(
  timesheet: Pick<TimesheetModel, 'department_id' | 'team_id' | 'shift_id' | 'starttime' | 'endtime' | 'break'> | null,
  schedule: Pick<ScheduleModel, 'department_id' | 'team_id' | 'shift_id' | 'starttime' | 'endtime' | 'break'> | null,
): TimesheetScheduleDeviations {
  const deviations = {
    department: false,
    team: false,
    shift: false,
    starttime: false,
    endtime: false,
    break: false,
    hasDeviations: false,
  };

  if (!timesheet || !schedule) return deviations;

  /**
   * TODO the fact that `appendSecondsToTimeString` exist says a lot how wildly inconsistent date/time related things are in shiftbase
   * The proper solution would be to use dateTime objects everywhere in the frontend and only communicate utc strings to/from the api, but that's a huge undertaking.
   * Hopefully in the future we'll at least create a proper transformation layer for our api so that at least in the frontend things can be consistent and predictable.
   */
  if (
    schedule.starttime &&
    timesheet.starttime &&
    appendSecondsToTimeString(schedule.starttime) !== appendSecondsToTimeString(timesheet.starttime)
  ) {
    deviations.starttime = true;
  }

  if (
    schedule.endtime &&
    timesheet.endtime &&
    appendSecondsToTimeString(schedule.endtime) !== appendSecondsToTimeString(timesheet.endtime)
  ) {
    deviations.endtime = true;
  }

  if (schedule.break && timesheet.break && schedule.break !== timesheet.break.toString()) {
    deviations.break = true;
  }

  if (schedule.department_id && timesheet.department_id && schedule.department_id !== timesheet.department_id) {
    deviations.department = true;
  }

  if (schedule.team_id && timesheet.team_id && schedule.team_id !== timesheet.team_id) {
    deviations.team = true;
  }

  if (schedule.shift_id && timesheet.shift_id && schedule.shift_id !== timesheet.shift_id) {
    deviations.shift = true;
  }

  deviations.hasDeviations = Object.values(deviations).some((value) => value);

  return deviations;
}

//add row to existing array and set filter status on row
const addRowToRows = (row, rows, keep) => {
  rows.push({ ...row, keep: keep });
};

const addTimesheetsToRows = (
  rows,
  timesheets,
  schedules,
  absence,
  filterFn,
  timesheetsForOverlapCheck: TimesheetModel[],
) => {
  timesheets.forEach((timesheet) => {
    const absenceWarning = checkTimesheetAbsenceWarning(timesheet, absence);
    const doubleRegistration = checkOverlappingTimesheetWarning(timesheet, timesheetsForOverlapCheck);

    const timesheetRow = {
      type: 'timesheet',
      timesheet,
      absenceWarning,
      doubleRegistration,
      deviationsFromSchedule: getDeviationsFromSchedule(timesheet, null),
    };

    const keepTimesheet = filterFn(timesheetRow);

    if (!timesheet.roster_id) {
      addRowToRows(timesheetRow, rows, keepTimesheet);
      return;
    }

    const occurrenceId = timesheet.roster_id + ':' + timesheet.date;
    // pull schedule from rosters
    const scheduleIndex = schedules.findIndex((schedule) => schedule.occurrence_id === occurrenceId);
    if (scheduleIndex === -1) {
      addRowToRows(
        {
          type: 'timesheet',
          timesheet: {
            ...timesheet,
            roster_id: void 0,
          },
          absenceWarning,
          doubleRegistration,
          deviationsFromSchedule: getDeviationsFromSchedule(timesheet, null),
        },
        rows,
        keepTimesheet,
      );
      return;
    }

    const schedule = schedules[scheduleIndex];

    addRowToRows(
      {
        ...timesheetRow,
        deviationsFromSchedule: getDeviationsFromSchedule(timesheet, schedule),
        schedule,
      },
      rows,
      keepTimesheet,
    );

    const scheduleRow = {
      type: 'schedule',
      schedule: {
        ...schedule,
        hasTimesheet: keepTimesheet ? true : false,
        timesheet,
      },
    };

    addRowToRows(scheduleRow, rows, filterFn(scheduleRow));

    //remove schedule from list so it wont be rendered twice;
    schedules.splice(scheduleIndex, 1);
  });
};

export const mapGroupedTimesheetData = (
  timesheets: TimesheetModel[],
  schedules: ScheduleModel[],
  absence: AbsenceModel[],
  filterFn,
  timesheetsForOverlapCheck: TimesheetModel[],
): TimesheetRow[] => {
  const rows = [];

  addTimesheetsToRows(rows, timesheets, schedules, absence, filterFn, timesheetsForOverlapCheck);

  schedules.forEach((schedule) => {
    const scheduleRow = {
      type: 'schedule',
      schedule: {
        ...schedule,
        hasTimesheet: false,
      },
    };

    addRowToRows(scheduleRow, rows, filterFn(scheduleRow));
  });

  absence.forEach((absentee) => {
    if (isEmpty(absentee.AbsenteeDay)) {
      return;
    }

    const absenceRow = {
      type: 'absentee',
      absentee,
    };

    addRowToRows(absenceRow, rows, filterFn(absenceRow));
  });

  return rows;
};

const mapEmployee =
  (
    groupedTimesheets,
    groupedSchedules,
    groupedAbsence,
    permissionState,
    employeeTeamDepartments,
    filterFn,
    timesheetsForOverlapCheck: TimesheetModel[],
  ): ((Employee: EmployeeModel) => TimesheetDayEmployee) =>
  (employee) => {
    const employeeDepartments = employeeTeamDepartments[employee.id] || null;

    const canViewSalary = hasPermission(
      {
        permissions: ['View salary', 'View own salary'],
        userId: employee.id,
        departments: employeeDepartments,
      },
      permissionState,
    );

    const canViewEmployee = hasPermission(
      {
        permissions: ['View all users'],
        userId: employee.id,
        departments: employeeDepartments,
      },
      permissionState,
    );

    const employeeTimesheets = groupedTimesheets[employee.id] || [];
    const employeeSchedules = groupedSchedules[employee.id] || [];
    const employeeAbsence = groupedAbsence[employee.id] || [];

    const trackedHours = sumTimesheets(employeeTimesheets);
    const scheduledHours = sumSchedules(employeeSchedules);
    const scheduleDiff = trackedHours.hours - scheduledHours.hours;

    const rows = mapGroupedTimesheetData(
      employeeTimesheets,
      employeeSchedules,
      employeeAbsence,
      filterFn,
      timesheetsForOverlapCheck,
    )
      //filter out all rows that should be filtered
      .filter((row) => row.keep);

    const filteredTimesheets = rows
      .filter((row) => row.type === 'timesheet')
      .map((row: TimesheetRowTimesheet) => row.timesheet);

    const filteredAbsence = rows
      .filter((row) => row.type === 'absentee')
      .map((row: TimesheetRowAbsentee) => row.absentee);

    const total = totalAccumulator(sumTimesheets(filteredTimesheets), sumPeriodAbsence(filteredAbsence));

    if (!canViewSalary) {
      total.pay = 0;
    }

    return { employee, rows, total, canViewSalary, canViewEmployee, scheduleDiff };
  };

export const getDataForTimesheetEmployeeDayPageWithCoc = createSelector(
  getTimesheetsForTimesheetPage,
  getSchedulesForTimesheetDayPage,
  getAbsenceForTimesheetPage,
  getEmployeesForTimesheetDayPageNewCoc,
  getDaylogsForTimesheet,
  getSelectedDepartmentIds,
  getPermissionState,
  getEmployeeTeamDepartments,
  getTimesheetRowFilter,
  getTimesheetRowFilterSettings,
  getTimesheetsForTimesheetPageWithoutDepartmentFilterWithCoC,
  (
    timesheets,
    schedules,
    absence,
    employees,
    daylogs,
    selectedDepartmentIds,
    permissionState,
    employeeTeamDepartments,
    rowFilter,
    rowFilterSettings,
    timesheetsForOverlapCheck,
  ): TimesheetEmployeeDayData => {
    const enhancedTimesheets = enhanceTimesheetsWithLogAndPermissions(timesheets, daylogs, permissionState);
    const groupedTimesheets = groupBy(enhancedTimesheets, 'user_id');
    const groupedSchedules = groupBy(schedules, 'user_id');
    const groupedAbsence = groupBy(absence, 'user_id');

    const mappedEmployees = employees
      .map(
        mapEmployee(
          groupedTimesheets,
          groupedSchedules,
          groupedAbsence,
          permissionState,
          employeeTeamDepartments,
          rowFilter,
          timesheetsForOverlapCheck,
        ),
      )
      .filter((employee) => {
        if (rowFilterSettings.show.employee) {
          return true;
        }

        return employee.rows && employee.rows.length > 0;
      });

    const grandTotal = mappedEmployees.reduce((acc, current) => totalAccumulator(acc, current.total), defaultTotal);

    const canViewSalaryForDepartments: PermissionCheck = {
      permissions: ['View salary'],
      departments: selectedDepartmentIds,
      userId: 'me',
    };

    return {
      employees: mappedEmployees,
      canViewSalaryForDepartment: hasPermission(canViewSalaryForDepartments, permissionState),
      total: grandTotal,
    };
  },
);

export const getDataForTimesheetEmployeeDayPageNewWithCoc = createSelector(
  getTimesheetsForTimesheetPageWithCoc,
  getSchedulesForTimesheetDayPage,
  getAbsenceForTimesheetPage,
  getEmployeesForTimesheetDayPageNewCoc,
  getDaylogsForTimesheet,
  getSelectedDepartmentIds,
  getPermissionState,
  getEmployeeTeamDepartments,
  getTimesheetRowFilter,
  getTimesheetRowFilterSettings,
  getTimesheetsForTimesheetPageWithoutDepartmentFilterWithCoC,
  (
    timesheets,
    schedules,
    absence,
    employees,
    daylogs,
    selectedDepartmentIds,
    permissionState,
    employeeTeamDepartments,
    rowFilter,
    rowFilterSettings,
    timesheetsForOverlapCheck,
  ): TimesheetEmployeeDayData => {
    const enhancedTimesheets = enhanceTimesheetsWithLogAndPermissions(timesheets, daylogs, permissionState);
    const groupedTimesheets = groupBy(enhancedTimesheets, 'user_id');
    const groupedSchedules = groupBy(schedules, 'user_id');
    const groupedAbsence = groupBy(absence, 'user_id');

    const mappedEmployees = employees
      .map(
        mapEmployee(
          groupedTimesheets,
          groupedSchedules,
          groupedAbsence,
          permissionState,
          employeeTeamDepartments,
          rowFilter,
          timesheetsForOverlapCheck,
        ),
      )
      .filter((employee) => {
        if (rowFilterSettings.show.employee) {
          return true;
        }

        return employee.rows && employee.rows.length > 0;
      });

    const grandTotal = mappedEmployees.reduce((acc, current) => totalAccumulator(acc, current.total), defaultTotal);

    const canViewSalaryForDepartments: PermissionCheck = {
      permissions: ['View salary'],
      departments: selectedDepartmentIds,
      userId: 'me',
    };

    return {
      employees: mappedEmployees,
      canViewSalaryForDepartment: hasPermission(canViewSalaryForDepartments, permissionState),
      total: grandTotal,
    };
  },
);

const departmentIdsToDepartmentTimesheetOptions = (ids, closedDepartmentIds, departmentsById, locationsById) => {
  const departments = filterDeleted(mapAndSortDepartments(ids, departmentsById))
    //add closed status to departments
    .map((department) => {
      const isClosed = closedDepartmentIds.indexOf(department.id) !== -1;
      return {
        ...department,
        isClosed,
      };
    });

  return getDepartmentOptionsPerLocation(departments, locationsById);
};

export const getTimesheetPageOptionsForSelectedDepartments = createSelector(
  getPermissionState,
  getLocationEntities,
  getDepartmentEntities,
  getTeams,
  getShifts,
  getRateCards,
  getDaylogsForTimesheet,
  getSelectedDepartmentIds,
  getTimesheetColumns,
  getTimesheetRowFilterSettings,
  (
    permissionState,
    locationsById,
    departmentsById,
    teams,
    shifts,
    rateCards,
    daylogs: DaylogModel[],
    selectedDepartmentIds,
    columns,
    rowFilters,
  ) => {
    const departmentIds = permissionDepartments(
      {
        permissions: ['Create timesheets'],
        departments: selectedDepartmentIds,
      },
      permissionState,
    );

    const ownDepartmentIds = permissionDepartments(
      {
        permissions: ['Create timesheets', 'Create own timesheet'],
        userId: 'me',
      },
      permissionState,
    );

    const closedDepartmentIds = daylogs
      .filter((daylog) => daylog.finished_timesheet)
      .map((daylog) => daylog.department_id);

    const canOpenOrClose = hasPermission(
      {
        permissions: ['Close timesheets', 'Open timesheets'],
        departments: selectedDepartmentIds,
      },
      permissionState,
    );

    const canApprove = hasPermission(
      {
        permissions: 'Approve timesheets',
        departments: selectedDepartmentIds,
      },
      permissionState,
    );

    const canClockOthers = hasPermission(
      {
        permissions: 'Clock time',
        departments: 'any',
      },
      permissionState,
    );

    return {
      authenticatedUserId: permissionState.userId,
      locationsById,
      departmentsById,
      departmentOptions: departmentIdsToDepartmentTimesheetOptions(
        departmentIds,
        closedDepartmentIds,
        departmentsById,
        locationsById,
      ),
      ownDepartmentOptions: departmentIdsToDepartmentTimesheetOptions(
        ownDepartmentIds,
        closedDepartmentIds,
        departmentsById,
        locationsById,
      ),
      teams,
      shifts,
      rateCards,
      columns,
      show: rowFilters.show,
      statusFilters: rowFilters.status,
      typeFilters: rowFilters.type,
      customFields: rowFilters.customFields,
      canOpenOrClose,
      canApprove,
      canClockOthers,
      canPrintTimesheetStatement: canPrintTimesheetStatement(permissionState, selectedDepartmentIds),
    };
  },
);

export const getTimesheetPageOptionsForAllDepartments = createSelector(
  getPermissionState,
  getLocationEntities,
  getDepartmentEntities,
  getTeams,
  getShifts,
  getRateCards,
  getDaylogsForTimesheet,
  getSelectedDepartmentIds,
  getTimesheetColumns,
  getTimesheetRowFilterSettings,
  (
    permissionState,
    locationsById,
    departmentsById,
    teams,
    shifts,
    rateCards,
    daylogs: DaylogModel[],
    selectedDepartmentIds,
    columns,
    rowFilters,
  ) => {
    const departmentIds = permissionDepartments(
      {
        permissions: ['Create timesheets'],
        departments: 'any',
      },
      permissionState,
    );

    const ownDepartmentIds = permissionDepartments(
      {
        permissions: ['Create timesheets', 'Create own timesheet'],
        userId: 'me',
        departments: 'any',
      },
      permissionState,
    );

    const closedDepartmentIds = daylogs
      .filter((daylog) => daylog.finished_timesheet)
      .map((daylog) => daylog.department_id);

    const canOpenOrClose = hasPermission(
      {
        permissions: ['Close timesheets', 'Open timesheets'],
        departments: 'any',
      },
      permissionState,
    );

    const canApprove = hasPermission(
      {
        permissions: 'Approve timesheets',
        departments: 'any',
      },
      permissionState,
    );

    const canClockOthers = hasPermission(
      {
        permissions: 'Clock time',
        departments: 'any',
      },
      permissionState,
    );

    return {
      authenticatedUserId: permissionState.userId,
      locationsById,
      departmentsById,
      departmentOptions: departmentIdsToDepartmentTimesheetOptions(
        departmentIds,
        closedDepartmentIds,
        departmentsById,
        locationsById,
      ),
      ownDepartmentOptions: departmentIdsToDepartmentTimesheetOptions(
        ownDepartmentIds,
        closedDepartmentIds,
        departmentsById,
        locationsById,
      ),
      teams,
      shifts,
      rateCards,
      columns,
      show: rowFilters.show,
      statusFilters: rowFilters.status,
      typeFilters: rowFilters.type,
      customFields: rowFilters.customFields,
      canOpenOrClose,
      canApprove,
      canClockOthers,
    };
  },
);

export const getTimesheetDepartments = (userId: string, date: string, permissions: PermissionOption) =>
  createSelector(
    getPermissionState,
    getLocationEntities,
    getDepartmentEntities,
    getDaylogs,
    (permissionState, locations, departments, daylogs) => {
      const logsOnDate = daylogs.filter(periodFilter(date, date));
      const closedDepartmentIds = logsOnDate
        .filter((daylog) => daylog.finished_timesheet)
        .map((daylog) => daylog.department_id);

      const departmentIds = permissionDepartments(
        {
          permissions,
          userId,
          departments: 'any',
        },
        permissionState,
      );

      const availableDepartments = difference(departmentIds, closedDepartmentIds);
      const departmentOptions = departmentIdsToDepartmentTimesheetOptions(
        departmentIds,
        closedDepartmentIds,
        departments,
        locations,
      );

      return {
        availableDepartments,
        departmentOptions,
      };
    },
  );

export const getTimesheetDepartmentsForUsers = (
  userIds: string[],
  minDate: string,
  maxDate: string,
  permissions: PermissionOption,
) =>
  createSelector(getPermissionState, getDaylogs, (permissionState, daylogs) => {
    const dayRange = dayList(minDate, maxDate);
    const logsOnDateRange = daylogs.filter(periodFilter(minDate, maxDate));
    const days = {};

    dayRange.forEach((date) => {
      const logsOnDate = logsOnDateRange.filter(periodFilter(date, date));
      const closedDepartmentIds = logsOnDate
        .filter((daylog) => daylog.finished_timesheet)
        .map((daylog) => daylog.department_id);

      const users = {};

      userIds.forEach((userId) => {
        const departmentIds = permissionDepartments(
          {
            permissions,
            userId,
            departments: 'any',
          },
          permissionState,
        );

        const availableDepartments = difference(departmentIds, closedDepartmentIds);
        users[userId] = { availableDepartments };
      });
      days[date] = users;
    });

    return days;
  });

export const getVisibleTeamsForTimesheet = createSelector(
  getTimesheetMaxDate,
  getTeams,
  (maxDate, teams: TeamModel[]) =>
    teams.filter((team) => {
      if (team.hidden) {
        return false;
      }
      if (!team.deleted_date) {
        return true;
      }
      const deletedDate = parseDate(team.deleted_date);
      const endDate = endOfDay(parseDate(maxDate));
      return deletedDate <= endDate;
    }),
);

export const getVisibleTeamsGroupedByDepartmentForTimesheet = createSelector(getVisibleTeamsForTimesheet, (teams) => {
  const groupedTeams = groupTeamsByDepartment(teams);
  return groupedTeams ? groupedTeams : [];
});
